import PropTypes from 'prop-types';
import { Link as RouterLink } from 'react-router-dom';
import { useTheme } from '@mui/material/styles';
import { Box } from '@mui/material';

Logo.propTypes = {
  disabledLink: PropTypes.bool,
  sx: PropTypes.object,
};

export default function Logo({ disabledLink = false, sx }) {
  const theme = useTheme();
  const PRIMARY_LIGHT = theme.palette.primary.light;
  const PRIMARY_MAIN = theme.palette.primary.main;
  const PRIMARY_DARK = theme.palette.primary.dark;
  const logo = (
    <Box sx={{ width: 40, height: 40, ...sx }}>
      <svg xmlns="http://www.w3.org/2000/svg" width="100%" height="100%"  viewBox="0 0 712.000000 538.000000">
        <defs>
          <linearGradient id="BG1" x1="100%" x2="50%" y1="9.946%" y2="50%">
            <stop offset="0%" stopColor={PRIMARY_DARK} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG2" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
          <linearGradient id="BG3" x1="50%" x2="50%" y1="0%" y2="100%">
            <stop offset="0%" stopColor={PRIMARY_LIGHT} />
            <stop offset="100%" stopColor={PRIMARY_MAIN} />
          </linearGradient>
        </defs>
        <g
          fill={PRIMARY_MAIN} stroke="none" strokeWidth="1" fillRule="evenodd"  transform="translate(0.000000,538.000000) scale(0.100000,-0.100000)">

<path d="M1778 5373 c980 -2 2584 -2 3565 0 980 1 178 2 -1783 2 -1961 0
-2763 -1 -1782 -2z"/>
<path d="M3983 5102 c-33 -5 -32 -71 3 -129 14 -25 23 -47 20 -50 -9 -9 -36
18 -36 37 0 20 -91 69 -135 71 -58 4 -139 -26 -187 -69 -36 -31 -22 -43 82
-72 25 -7 58 -16 74 -21 16 -5 53 -9 82 -9 l54 0 -21 -41 c-17 -32 -19 -45
-11 -63 9 -20 20 -24 90 -30 76 -7 137 1 170 23 8 4 16 27 19 50 6 44 15 49
58 29 27 -12 34 -28 12 -28 -22 0 -109 -96 -105 -116 1 -11 20 -27 40 -37 44
-22 161 -40 199 -32 38 8 63 40 63 79 0 32 1 32 26 19 14 -7 36 -22 50 -32
l24 -19 -40 -11 c-40 -11 -114 -73 -114 -96 0 -30 126 -100 196 -109 57 -7 91
6 114 47 13 23 13 30 2 48 l-14 22 29 -21 c15 -11 39 -31 52 -45 l23 -26 -43
-12 c-62 -18 -108 -50 -116 -78 -5 -21 2 -32 43 -67 102 -89 204 -116 244 -64
11 14 20 33 20 43 0 28 21 15 58 -36 l34 -47 -53 0 c-61 0 -135 -31 -144 -60
-14 -43 136 -192 205 -204 50 -9 100 23 100 63 0 16 -5 32 -10 34 -6 3 -5 4 2
3 6 -1 29 -35 49 -74 l37 -73 -36 8 c-89 18 -182 -5 -182 -45 0 -22 22 -72 33
-72 4 0 7 -9 7 -19 0 -11 5 -23 11 -27 6 -3 18 -19 28 -35 29 -48 89 -89 130
-89 54 0 84 25 77 64 l-6 31 19 -25 c11 -13 17 -28 15 -32 -3 -4 0 -15 5 -25
11 -21 31 -103 25 -103 -3 0 -17 9 -32 20 -34 25 -127 43 -167 32 -49 -13 -46
-55 13 -201 40 -100 98 -142 172 -126 31 7 45 31 34 59 -4 11 -1 16 10 16 13
0 16 -11 16 -57 0 -32 3 -75 6 -95 5 -30 3 -38 -9 -38 -8 0 -17 6 -20 14 -3 7
-27 26 -53 41 -36 21 -62 28 -103 29 l-56 1 -4 -35 c-4 -47 18 -196 37 -245
22 -58 64 -95 106 -95 52 0 66 9 66 42 0 19 4 28 13 25 13 -4 13 -37 1 -124
-3 -29 -10 -53 -15 -53 -5 0 -9 -7 -9 -17 0 -10 -11 -1 -29 23 -39 54 -70 78
-126 99 -47 19 -48 19 -65 -2 -10 -13 -22 -59 -30 -122 -19 -148 0 -219 73
-265 41 -26 103 -2 92 35 -4 11 -2 19 4 19 13 0 15 -62 3 -76 -5 -5 -15 -28
-22 -51 -7 -24 -17 -43 -22 -43 -4 0 -8 -9 -8 -20 0 -33 -19 -23 -40 23 -34
71 -114 147 -155 147 -17 0 -28 -10 -43 -43 -10 -23 -23 -53 -26 -67 -4 -14
-11 -34 -16 -45 -32 -76 -40 -167 -17 -208 24 -43 57 -67 93 -67 34 0 34 0 34
48 1 46 1 46 15 22 22 -39 18 -58 -22 -111 -21 -26 -49 -64 -63 -83 -14 -20
-28 -36 -31 -36 -3 0 -11 26 -18 57 -19 97 -85 203 -126 203 -17 0 -136 -161
-168 -228 -38 -79 -31 -145 22 -196 39 -37 71 -32 86 14 l12 35 6 -30 c6 -34
-17 -61 -115 -133 -67 -49 -83 -53 -74 -16 17 66 18 88 6 127 -6 23 -16 50
-21 59 -5 10 -9 24 -9 31 0 7 -11 24 -25 37 l-24 23 -42 -29 c-23 -16 -51 -34
-62 -41 -12 -6 -51 -43 -87 -81 -73 -76 -96 -129 -84 -194 7 -35 49 -88 70
-88 7 0 21 11 32 25 25 32 31 32 28 -2 -2 -21 -11 -31 -32 -38 -16 -5 -31 -12
-34 -15 -16 -17 -167 -66 -176 -57 -2 2 6 26 20 53 43 87 39 256 -7 268 -38
10 -248 -80 -303 -131 -75 -67 -96 -156 -49 -207 28 -30 41 -32 67 -9 17 15
18 15 18 -9 0 -15 -7 -29 -17 -34 -24 -10 -361 -18 -416 -10 -52 8 -62 15 -72
46 -7 24 -7 24 12 7 27 -23 59 -21 77 5 21 29 20 101 0 144 -18 38 -104 111
-141 120 -12 4 -23 10 -23 15 0 5 -7 9 -15 9 -8 0 -23 4 -33 10 -28 15 -111
40 -136 40 -50 0 -63 -153 -21 -254 13 -33 22 -62 20 -64 -4 -4 -90 20 -130
37 -11 5 -39 17 -62 26 -38 16 -43 22 -43 49 l1 31 20 -27 c27 -36 51 -35 83
3 21 25 26 41 26 85 0 82 -46 145 -185 252 -88 68 -103 68 -140 -3 -26 -50
-28 -64 -28 -149 l1 -94 -32 21 c-103 71 -156 119 -156 143 0 31 18 31 26 -1
10 -40 55 -34 93 12 25 30 31 47 31 85 0 27 -4 56 -9 66 -5 9 -15 31 -22 47
-33 73 -136 205 -160 205 -20 0 -71 -51 -88 -87 -7 -15 -18 -36 -23 -46 -5
-10 -12 -41 -15 -68 -3 -27 -9 -49 -14 -49 -8 0 -48 49 -101 125 -30 43 -34
54 -25 75 l11 25 4 -30 c5 -41 15 -49 56 -41 52 10 97 74 90 130 -12 110 -17
133 -36 176 -63 142 -70 148 -122 107 -65 -50 -85 -77 -115 -150 -13 -34 -25
-29 -46 21 -9 20 -23 51 -31 67 -24 52 -28 91 -9 98 13 6 15 3 10 -14 -4 -13
-1 -27 10 -39 25 -27 86 -8 122 39 26 33 28 44 31 141 1 60 -3 128 -11 160
-11 49 -15 55 -40 58 -48 6 -177 -78 -177 -114 0 -6 -6 -14 -14 -17 -11 -4
-17 7 -25 41 -19 89 -24 152 -13 152 6 0 12 -12 14 -27 4 -40 53 -52 104 -27
53 26 84 90 100 206 14 104 9 157 -15 159 -68 7 -144 -21 -187 -68 -13 -15
-27 -24 -30 -20 -9 9 8 187 18 187 5 0 7 -9 3 -19 -11 -35 48 -70 98 -57 29 8
77 53 100 93 29 52 56 145 57 190 0 40 -2 42 -32 47 -18 3 -42 5 -53 5 -27 -1
-117 -29 -134 -43 -11 -9 -13 -7 -8 9 3 11 11 43 18 70 7 28 19 59 27 70 13
19 14 19 8 -7 -5 -21 0 -31 20 -47 52 -41 116 -22 176 51 36 44 98 154 98 174
0 35 -106 61 -172 44 -22 -6 -42 -9 -45 -6 -7 6 45 112 62 126 9 8 11 4 7 -15
-10 -56 89 -86 153 -46 68 43 155 142 155 178 0 29 -58 59 -127 67 -13 1 -26
6 -30 9 -3 4 -12 2 -20 -4 -29 -25 -12 15 28 62 42 50 69 66 49 29 -21 -39 20
-79 83 -82 35 -1 100 26 131 54 11 10 35 28 53 41 39 26 43 60 11 90 -30 25
-81 47 -113 47 -33 0 -32 10 8 48 40 38 57 41 57 7 0 -13 10 -34 23 -45 18
-18 31 -21 83 -18 63 4 158 43 191 80 16 17 16 22 2 46 -18 32 -85 80 -119 84
-24 3 -23 5 17 31 24 15 44 27 46 27 2 0 9 -18 16 -40 20 -69 83 -85 204 -53
74 19 102 41 92 72 -9 29 -93 111 -114 111 -9 0 4 9 28 20 50 23 82 27 63 8
-27 -27 -6 -78 44 -104 61 -32 226 -7 232 35 4 23 -52 111 -71 111 -19 1 -49
27 -39 34 5 3 26 -5 46 -19 57 -38 195 -34 251 7 58 43 76 63 73 81 -7 45
-212 62 -287 24 -19 -9 -35 -17 -36 -17 -1 0 1 28 3 62 l5 61 -57 -6 c-64 -6
-146 -41 -193 -81 -49 -41 -44 -105 10 -133 14 -7 8 -12 -33 -27 -51 -18 -74
-16 -52 6 19 19 16 157 -3 164 -23 9 -136 -50 -192 -100 -73 -66 -77 -131 -9
-161 28 -12 29 -13 11 -21 -11 -4 -37 -18 -58 -31 l-39 -24 7 53 c8 62 -8 136
-32 145 -31 12 -145 -72 -193 -142 -38 -55 -41 -111 -7 -143 13 -12 32 -22 42
-22 14 0 8 -10 -24 -40 -23 -22 -46 -40 -51 -40 -6 0 -10 20 -10 45 0 63 -33
145 -59 145 -35 0 -147 -127 -174 -198 -23 -58 -21 -92 7 -124 27 -32 76 -39
76 -11 0 9 3 14 6 10 11 -10 -6 -41 -45 -84 -22 -24 -42 -43 -45 -43 -3 0 -6
13 -6 29 0 17 -4 33 -10 36 -5 3 -10 13 -10 21 0 25 -53 94 -72 94 -9 0 -30
-17 -45 -37 -15 -21 -31 -40 -34 -43 -15 -12 -68 -123 -63 -132 3 -5 0 -22 -6
-39 -31 -73 59 -170 113 -122 23 21 23 20 -22 -69 -30 -62 -51 -79 -51 -43 0
8 -9 30 -19 49 -11 19 -17 37 -14 40 4 3 1 6 -5 6 -7 0 -25 14 -40 31 -39 43
-62 34 -94 -39 -47 -106 -53 -127 -53 -202 0 -63 4 -81 24 -109 23 -32 26 -33
77 -29 50 4 68 -2 44 -17 -6 -3 -10 -16 -10 -27 0 -26 -21 -98 -29 -98 -3 0
-20 22 -38 49 -29 45 -117 115 -136 108 -5 -1 -14 -14 -22 -27 -39 -74 -47
-248 -15 -332 18 -49 25 -56 61 -68 35 -12 45 -12 68 1 16 10 26 23 25 37 0
12 4 22 11 22 8 0 10 -29 7 -97 -2 -54 -6 -100 -8 -102 -3 -3 -23 14 -47 36
-48 47 -125 86 -159 81 -22 -3 -23 -6 -25 -113 -5 -188 46 -306 138 -321 46
-8 93 27 85 62 -7 27 10 33 18 7 7 -21 26 -150 26 -175 0 -15 -5 -14 -37 8
-20 13 -48 27 -62 31 -14 3 -31 10 -38 15 -6 5 -35 8 -65 6 l-53 -3 3 -55 c4
-55 38 -173 64 -220 52 -96 91 -131 153 -137 35 -5 46 -1 68 21 29 29 34 55
15 74 -6 6 -9 15 -5 19 4 4 13 1 19 -5 12 -12 88 -194 83 -200 -1 -1 -28 4
-61 13 -95 24 -214 13 -214 -20 0 -22 61 -155 71 -155 5 0 9 -4 9 -9 0 -16 56
-94 87 -121 54 -49 86 -64 140 -64 60 -1 89 19 99 71 5 27 2 33 -22 47 l-29
15 27 1 c22 0 38 -16 97 -92 l70 -93 -82 -3 c-45 -2 -86 -7 -91 -12 -6 -6 -20
-10 -33 -10 -13 0 -23 -4 -23 -10 0 -5 -8 -10 -18 -10 -21 0 -42 -18 -42 -37
0 -20 114 -147 169 -187 114 -84 190 -108 253 -81 47 19 70 54 66 97 -3 30 -7
34 -48 44 l-45 11 38 7 c35 7 42 3 137 -70 55 -42 100 -80 100 -85 0 -5 -18
-9 -40 -9 -22 0 -48 -4 -58 -9 -9 -5 -38 -19 -64 -31 -56 -27 -111 -81 -106
-105 5 -29 111 -98 206 -136 12 -5 32 -13 45 -19 41 -18 150 -30 191 -20 53
12 96 57 102 106 8 63 -9 74 -91 60 l-40 -7 39 21 c22 11 44 20 49 20 11 0
162 -57 189 -71 10 -5 24 -9 32 -9 34 0 23 -21 -22 -44 -66 -34 -131 -89 -169
-146 -39 -58 -34 -67 57 -101 59 -22 80 -24 240 -24 187 0 180 -2 235 55 29
29 41 78 28 116 -16 47 -35 51 -97 21 -62 -30 -83 -35 -46 -10 14 9 35 25 48
37 22 19 35 20 278 21 l254 1 27 -28 c25 -26 25 -28 6 -28 -11 0 -26 7 -33 15
-18 22 -67 19 -80 -4 -5 -11 -10 -38 -10 -60 0 -33 7 -49 36 -80 19 -22 51
-45 71 -52 44 -14 328 -8 358 8 11 6 43 20 70 30 28 11 51 26 52 34 4 18 -92
148 -115 156 -9 4 -36 19 -59 35 -23 15 -46 28 -52 28 -6 0 -11 4 -11 9 0 4
38 20 84 35 46 15 94 34 105 43 29 19 70 19 106 -2 l30 -17 -57 6 -56 6 -7
-39 c-9 -53 16 -104 60 -125 59 -28 182 -16 280 29 17 8 41 18 55 24 48 20
145 97 148 117 4 28 -53 72 -160 125 -10 5 -29 9 -42 9 -61 0 -73 26 -25 54
16 10 57 40 89 67 49 41 66 49 100 49 22 0 40 -4 40 -10 0 -5 -15 -10 -34 -10
-68 0 -82 -24 -51 -87 25 -49 42 -58 114 -58 60 1 72 5 132 42 71 44 133 99
198 176 35 41 39 51 30 68 -20 37 -91 59 -195 59 -75 0 -93 3 -84 13 6 6 21
24 34 39 13 15 43 56 65 91 37 57 45 63 68 59 25 -5 25 -6 8 -18 -10 -8 -22
-14 -27 -14 -15 0 -8 -61 10 -79 32 -34 54 -40 110 -30 46 9 60 18 117 76 36
36 65 72 65 79 0 7 7 20 16 29 24 23 74 132 74 159 0 13 -7 27 -16 30 -30 12
-172 6 -213 -9 -46 -16 -50 -10 -25 38 30 58 34 67 34 80 0 23 29 75 45 81 17
6 21 -9 5 -19 -16 -10 -11 -48 8 -71 27 -34 76 -38 120 -10 64 41 106 106 145
226 35 107 40 158 16 170 -21 12 -129 2 -129 -11 0 -5 -10 -9 -22 -9 -11 0
-36 -11 -54 -25 -40 -31 -48 -31 -39 -2 3 12 10 55 14 95 5 41 14 79 21 85 10
10 12 7 7 -12 -12 -51 64 -88 125 -61 24 12 68 71 68 93 0 7 6 19 12 25 9 9
13 57 13 160 l0 147 -33 -3 c-47 -3 -105 -35 -150 -82 l-39 -41 -6 95 c-4 53
-4 103 -1 111 10 26 22 16 20 -17 -1 -28 3 -33 30 -41 39 -11 78 5 108 46 41
56 31 331 -14 372 -15 14 -20 14 -47 0 -36 -18 -95 -80 -118 -125 -18 -35 -35
-31 -35 9 0 15 -4 31 -9 37 -14 15 -23 84 -11 84 5 0 10 -4 10 -10 0 -5 15
-10 34 -10 69 0 104 74 91 192 -7 54 -9 63 -39 131 -9 21 -16 44 -16 52 0 8
-4 15 -10 15 -5 0 -10 6 -10 14 0 32 -24 26 -65 -14 -38 -37 -61 -76 -79 -132
-9 -30 -20 -21 -49 42 -16 33 -33 62 -38 65 -5 4 -9 16 -8 28 l0 22 9 -22 c14
-35 73 -34 105 0 28 30 26 129 -5 194 -30 65 -100 163 -116 163 -21 0 -44 -12
-44 -22 0 -5 -9 -22 -19 -37 -11 -15 -22 -45 -26 -66 -13 -68 -17 -68 -73 -3
-29 33 -50 63 -47 67 4 3 13 -2 20 -13 12 -16 17 -17 43 -5 16 7 37 27 47 45
20 37 12 72 -36 159 -33 60 -131 150 -154 141 -22 -8 -45 -68 -53 -137 l-7
-58 -43 36 -43 35 36 25 c32 22 35 28 35 72 0 42 -6 56 -40 99 -21 28 -50 58
-62 67 -131 91 -151 84 -150 -57 0 -38 -3 -68 -8 -68 -11 0 -99 50 -105 61 -4
5 1 6 10 3 8 -4 26 1 40 10 19 13 25 25 25 55 0 64 -46 113 -159 168 l-75 36
-17 -22 c-20 -24 -19 -93 2 -143 15 -36 10 -41 -25 -23 -16 8 -36 15 -46 15
-10 0 -23 7 -30 15 -11 13 -8 15 13 15 47 0 62 61 28 109 -37 52 -169 104
-238 93z m-1183 -376 c0 -3 -4 -8 -10 -11 -5 -3 -10 -1 -10 4 0 6 5 11 10 11
6 0 10 -2 10 -4z m2566 -1013 c-10 -10 -19 5 -10 18 6 11 8 11 12 0 2 -7 1
-15 -2 -18z m-3489 -5 c-3 -8 -6 -5 -6 6 -1 11 2 17 5 13 3 -3 4 -12 1 -19z"/>
<path d="M4281 3473 c-133 -47 -197 -214 -132 -342 20 -38 114 -126 164 -152
12 -7 46 -30 75 -51 47 -35 52 -42 52 -78 0 -84 -87 -103 -231 -50 -46 16 -84
29 -85 27 -3 -2 -6 -83 -5 -113 1 -13 10 -24 24 -28 111 -33 143 -39 232 -40
114 -1 152 13 217 81 50 52 68 99 68 181 0 60 -4 74 -31 116 -29 44 -94 96
-216 174 -50 33 -73 68 -66 104 13 68 90 80 221 34 l72 -25 0 64 0 64 -41 11
c-77 21 -191 40 -232 39 -23 0 -62 -8 -86 -16z"/>
<path d="M3508 3473 l-108 -4 0 -409 0 -410 143 0 c133 1 225 13 287 38 44 17
130 103 156 155 52 101 68 264 38 381 -32 124 -96 204 -187 231 -52 15 -186
23 -329 18z m229 -144 c9 -8 27 -38 40 -67 20 -46 23 -68 23 -183 0 -216 -41
-299 -146 -299 l-24 0 0 286 0 287 45 -5 c25 -3 52 -12 62 -19z"/>
<path d="M2650 3060 l0 -411 113 3 112 3 5 172 5 172 45 -87 c60 -116 127
-234 140 -249 16 -19 250 -18 250 0 0 7 -4 17 -9 23 -14 14 -251 444 -251 454
0 3 28 45 63 93 34 48 86 120 114 162 l52 75 -97 0 -98 0 -91 -152 c-134 -225
-122 -223 -123 -20 l0 172 -115 0 -115 0 0 -410z"/>
<path d="M1073 710 c-10 -10 -28 -58 -41 -107 -13 -48 -27 -97 -32 -108 -11
-24 -46 -138 -54 -177 -6 -27 -5 -28 32 -28 39 0 39 0 56 58 9 32 16 65 16 74
0 20 14 24 65 24 l39 -1 17 -67 c10 -38 21 -72 24 -78 7 -11 115 -14 115 -2 0
8 -13 53 -63 227 -19 66 -37 131 -41 145 -10 43 -22 52 -70 56 -35 3 -50 -1
-63 -16z m46 -127 c26 -98 26 -93 -6 -93 -16 0 -32 2 -35 5 -5 5 23 125 29
125 1 0 7 -17 12 -37z"/>
<path d="M1377 722 c-16 -3 -17 -23 -15 -215 l3 -212 90 2 c55 1 104 8 127 17
100 42 145 206 87 321 -34 68 -73 86 -187 88 -49 1 -96 1 -105 -1z m182 -89
c29 -64 25 -214 -7 -252 -9 -11 -29 -22 -44 -25 l-28 -5 0 155 0 154 33 0 c27
0 36 -6 46 -27z"/>
<path d="M3012 722 l-42 -3 2 -212 3 -212 80 1 c159 2 221 46 245 176 12 64
-2 148 -33 190 -38 51 -117 70 -255 60z m157 -84 c19 -30 26 -162 12 -216 -12
-45 -50 -78 -76 -68 -12 4 -15 31 -15 156 l0 150 33 0 c23 0 36 -7 46 -22z"/>
<path d="M3468 722 c-37 -3 -37 -4 -62 -80 -13 -42 -41 -133 -61 -201 -20 -68
-39 -130 -42 -137 -4 -10 4 -14 28 -14 48 0 55 8 69 75 7 33 16 65 22 72 5 6
28 10 51 10 l41 -2 16 -60 c26 -99 23 -95 85 -95 30 0 55 3 55 8 0 7 -26 101
-61 225 -11 39 -27 100 -36 134 -17 67 -25 72 -105 65z m22 -160 c17 -61 14
-72 -20 -72 -34 0 -36 8 -19 75 13 51 24 50 39 -3z"/>
<path d="M3658 722 c-14 -3 -18 -14 -18 -48 0 -48 8 -53 50 -29 59 34 59 32
62 -167 l3 -183 34 -3 c19 -2 44 -1 57 2 l24 6 0 180 c0 173 1 180 20 180 11
0 34 -9 52 -20 18 -11 36 -20 41 -20 4 0 7 23 7 50 0 45 -2 50 -22 50 -13 0
-84 2 -158 3 -74 1 -143 1 -152 -1z"/>
<path d="M4099 723 c-28 -4 -29 -7 -123 -314 -35 -116 -35 -121 11 -117 37 3
37 4 51 63 21 87 23 90 73 90 l44 0 20 -72 c21 -77 29 -83 106 -83 35 0 35 3
4 115 -18 66 -83 289 -90 312 -1 5 -74 10 -96 6z m29 -152 c20 -65 16 -81 -18
-81 -16 0 -30 4 -30 10 0 25 23 110 29 110 4 0 13 -17 19 -39z"/>
<path d="M4540 708 c-41 -28 -60 -61 -60 -107 0 -47 32 -90 101 -135 62 -40
77 -63 59 -96 -8 -16 -21 -20 -56 -20 -25 0 -58 7 -73 15 -32 16 -41 12 -41
-21 0 -51 174 -72 232 -29 51 39 66 118 32 170 -9 14 -43 44 -75 66 -70 47
-85 70 -67 100 11 18 20 20 63 16 28 -3 58 -8 68 -12 13 -6 17 -2 17 19 0 32
-26 44 -109 52 -48 5 -61 3 -91 -18z"/>
<path d="M6221 718 c-106 -30 -170 -157 -141 -281 14 -62 44 -102 95 -127 74
-36 216 -17 223 29 3 19 -1 20 -78 18 -78 -2 -83 -1 -102 24 -16 21 -19 42
-19 122 -1 148 23 175 142 161 58 -7 59 -6 59 19 0 22 -6 27 -50 36 -60 13
-83 13 -129 -1z"/>
<path d="M6822 710 c-32 -20 -62 -71 -62 -107 0 -35 41 -98 79 -122 87 -53
111 -92 76 -121 -15 -12 -83 -10 -132 5 -20 7 -23 4 -23 -22 0 -26 5 -31 39
-41 65 -20 143 -15 181 11 47 31 69 94 50 144 -14 41 -17 44 -102 104 -61 42
-74 73 -43 99 14 12 92 8 128 -6 8 -3 12 4 12 22 -2 24 -8 29 -51 40 -70 19
-114 17 -152 -6z"/>
<path d="M150 511 c0 -228 -2 -221 60 -221 57 0 60 4 60 104 l0 91 56 -97 55
-98 60 0 c61 0 70 7 44 34 -8 8 -15 16 -15 19 0 2 -25 49 -55 105 l-55 100 55
80 c30 44 55 83 55 87 0 3 -19 5 -41 3 -40 -3 -44 -6 -83 -69 -79 -128 -75
-127 -75 -21 l-1 92 -60 0 -60 0 0 -209z"/>
<path d="M562 508 l3 -213 55 0 55 0 5 95 5 95 45 0 c33 0 45 -4 46 -15 1 -8
2 -52 3 -97 l1 -83 54 0 c43 0 55 4 58 16 2 10 2 106 0 215 l-3 199 -54 0 -54
0 -3 -82 -3 -83 -47 -3 -48 -3 0 85 0 86 -60 0 -60 0 2 -212z"/>
<path d="M1752 508 l3 -213 86 2 c113 3 157 21 198 85 42 63 52 155 26 225
-35 94 -72 113 -216 113 l-99 0 2 -212z m196 107 c8 -25 12 -73 10 -128 -3
-83 -5 -90 -32 -113 -55 -48 -61 -36 -64 134 l-3 153 38 -3 c34 -3 39 -7 51
-43z"/>
<path d="M2130 558 c0 -140 3 -167 20 -202 24 -51 64 -70 146 -71 51 0 66 4
96 27 48 36 58 83 58 263 l0 146 -42 -3 -43 -3 -3 -169 c-1 -104 -7 -175 -13
-183 -12 -14 -56 -17 -83 -7 -14 5 -16 31 -16 185 l0 179 -60 0 -60 0 0 -162z"/>
<path d="M2520 505 l0 -216 133 3 132 3 3 54 c3 53 3 53 -24 47 -14 -4 -46
-16 -70 -27 -24 -12 -48 -18 -54 -14 -6 4 -10 71 -10 186 l0 179 -55 0 -55 0
0 -215z"/>
<path d="M4810 505 l0 -216 143 3 142 3 0 25 c0 24 -2 25 -82 28 l-83 3 0 70
0 71 33 -3 c17 -1 47 -1 65 0 29 1 33 4 30 24 -3 20 -9 22 -63 23 l-60 1 -3
67 -3 66 81 0 c79 0 80 0 80 25 l0 25 -140 0 -140 0 0 -215z"/>
<path d="M5152 509 l3 -214 55 0 55 0 2 84 c2 87 7 107 20 75 5 -10 27 -52 51
-91 l43 -73 59 0 c58 0 70 6 51 26 -6 5 -32 49 -60 98 l-49 88 40 39 c38 36
40 41 35 80 -3 26 -15 52 -31 68 -24 24 -33 25 -151 29 l-126 4 3 -213z m180
103 c3 -35 -1 -50 -16 -64 -37 -34 -48 -20 -50 63 -1 62 6 72 41 56 16 -7 22
-20 25 -55z"/>
<path d="M5509 618 c68 -246 85 -304 94 -315 12 -17 88 -17 109 -1 15 12 47
107 98 288 11 41 25 83 30 92 17 30 11 38 -30 38 -38 0 -40 -1 -50 -42 -13
-51 -34 -126 -56 -203 -8 -30 -19 -55 -24 -55 -4 0 -22 60 -40 133 -17 72 -34
140 -36 150 -4 14 -17 17 -64 17 l-59 0 28 -102z"/>
<path d="M5892 508 l3 -213 54 0 55 0 0 213 1 212 -58 0 -57 0 2 -212z"/>
<path d="M6432 508 l3 -213 135 0 135 0 0 25 c0 24 -2 25 -82 28 l-83 3 0 70
0 70 28 -2 c15 -1 44 -1 65 -1 34 2 38 5 35 25 -3 20 -9 22 -63 23 l-60 1 -5
66 -5 67 88 0 c86 0 87 0 87 25 l0 25 -140 0 -140 0 2 -212z"/>
<path d="M0 210 c0 -7 1185 -10 3535 -10 2350 0 3535 3 3535 10 0 7 -1185 10
-3535 10 -2350 0 -3535 -3 -3535 -10z"/>
</g>
</svg>

    </Box>
  );
  if (disabledLink) {
    return <>{logo}</>;
  }

  return <RouterLink to="">{logo}</RouterLink>
}
